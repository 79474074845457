import React from 'react'
import { ConfigProvider, DatePicker } from 'antd'
import { CommonVN } from 'constants/vn'
import { MAX_DATE, MIN_DATE } from 'constants/constants'
import 'moment'
import vn from 'antd/locale/vi_VN'
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
dayjs.locale('vn')

const months = [
  ['Tháng 1', 'Tháng 1'], //[["name","shortName"], ... ]
  ['Tháng 2', 'Tháng 2'],
  ['Tháng 3', 'Tháng 3'],
  ['Tháng 4', 'Tháng 4'],
  ['Tháng 5', 'Tháng 5'],
  ['Tháng 6', 'Tháng 6'],
  ['Tháng 7', 'Tháng 7'],
  ['Tháng 8', 'Tháng 8'],
  ['Tháng 9', 'Tháng 9'],
  ['Tháng 10', 'Tháng 10'],
  ['Tháng 11', 'Tháng 11'],
  ['Tháng 12', 'Tháng 12']
]
const weekDays = [
  ['CN', 'CN'], //[["name","shortName"], ... ]
  ['T2', 'T2'],
  ['T3', 'T3'],
  ['T4', 'T4'],
  ['T5', 'T5'],
  ['T6', 'T6'],
  ['T7', 'T7']
]

export const FormDate = ({ ...props }) => {
  const { disabled, disabledDate, ...rest } = props

  return (
    <ConfigProvider locale={vn}>
      <DatePicker
        months={months}
        weekDays={weekDays}
        className='w-100'
        size='large'
        disabled={disabled}
        // inputReadOnly
        placeholder={CommonVN.datePlaceholder}
        format={CommonVN.datePlaceholder.toUpperCase()}
        disabledDate={(current) => {
          return (disabledDate && disabledDate(current)) || current.isBefore(MIN_DATE) || current.isAfter(MAX_DATE)
        }}
        {...rest}
      />
    </ConfigProvider>
  )
}
