import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { getManageProgram as onGetManageProgram, setFilter, getProgramDetailSuccess } from 'store/actions'

import { Status, ProgramName, ProgramNo, ProgramType, ApplyDate, ConfigType, ApplyClientType } from './ManageProgramCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ManageProgramVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  acceptMemberAPI,
  deleteProgramAPI,
  getChannelAPI,
  getPortfolioAllAPI,
  getRankAllAPI,
  lockAccountAPI,
  patchStatusProgramAPI
} from 'helpers/backend_helper'
import { checkIsLocked, getStorage, setStorage } from 'helpers/global_helper'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { PROGRAMING_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import moment from 'moment'

function ManageProgram() {
  //meta title
  document.title = 'Program List | HPG'
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const screenCode = useMemo(() => SCREEN_CODE.PROGRAMING_MANAGEMENT, [])

  const selectProgramsState = (state) => state.ProgramReducer
  const ProgramsProperties = createSelector(selectProgramsState, (programReducer) => ({
    programs: programReducer.programs,
    loading: programReducer.loading,
    total: programReducer.total
  }))
  const { programs, loading, total } = useSelector(ProgramsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showDelete, setShowDelete] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [selected, setSelected] = useState()
  const [showPlay, setShowPlay] = useState(false)

  useEffect(() => {
    const filterManageProgram = getStorage('filterManageProgram')

    dispatch(setFilter({ page: 1 }))
    dispatch(onGetManageProgram({ ...filterManageProgram, page: 1 }))
  }, [dispatch])

  const onClickDelete = (item) => {
    setSelected(item)
    setShowDelete(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: ManageProgramVN.table.code,
        accessor: 'code',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProgramNo {...cellProps} />
        }
      },
      {
        Header: ManageProgramVN.table.type,
        accessor: 'type',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProgramType {...cellProps} />
        }
      },
      {
        Header: ManageProgramVN.table.name,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProgramName {...cellProps} />
        }
      },
      {
        Header: ManageProgramVN.table.applyDate,
        accessor: 'startDate',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ApplyDate {...cellProps} />
        }
      },
      {
        Header: ManageProgramVN.table.typeAccount,
        accessor: 'applyClientTypes',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ApplyClientType {...cellProps} />
        }
      },
      {
        Header: ManageProgramVN.table.bonus,
        accessor: 'configType',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ConfigType {...cellProps} />
        }
      },
      {
        Header: ManageProgramVN.table.status,
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />
        }
      },
      {
        Header: 'Thao tác',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          // console.log(cellProps)
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <PermissionWrapper screenCode={screenCode} code={PROGRAMING_MANAGEMENT_CODE.ADMIN_DETAIL_PROGRAMMING}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`/manage-program/view/${cellProps.row.original.id}`}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-eye-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`viewtooltip-${cellProps.row.original.id}`}
                    >
                      Xem
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
              <PermissionWrapper
                screenCode={screenCode}
                code={PROGRAMING_MANAGEMENT_CODE.ADMIN_CHANGE_STATUS_PROGRAMMING}
              >
                <li>
                  <Link
                    to='#'
                    className={`btn btn-sm btn-soft-success ${
                      cellProps.row.original?.status === 'active' || cellProps.row.original?.status === 'pause'
                        ? ''
                        : 'opacity-50'
                    }`}
                    onClick={() => {
                      if (cellProps.row.original?.status === 'active' || cellProps.row.original?.status === 'pause') {
                        const programData = cellProps.row.original
                        setSelected(programData)
                        if (cellProps.row.original.status !== 'pause') {
                          setShowPause(true)
                        } else {
                          setShowPlay(true)
                        }
                      }
                    }}
                    id={`accepttooltip-${cellProps.row.original.id}`}
                  >
                    <i
                      className={`mdi ${cellProps.row.original.status !== 'pause' ? 'mdi-pause' : 'mdi-play-outline'}`}
                    />
                    <UncontrolledTooltip
                      trigger='hover'
                      option
                      placement='top'
                      target={`accepttooltip-${cellProps.row.original.id}`}
                    >
                      {cellProps.row.original.status !== 'pause' ? 'Tạm dừng' : 'Tiếp tục'}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>

              <PermissionWrapper
                screenCode={screenCode}
                code={[
                  PROGRAMING_MANAGEMENT_CODE.ADMIN_DETAIL_PROGRAMMING,
                  PROGRAMING_MANAGEMENT_CODE.ADMIN_UPDATE_PROGRAMMING
                ]}
              >
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={
                      cellProps.row.original?.status === 'draft' || cellProps.row.original?.status === 'rejected'
                        ? `/manage-program/edit/${cellProps.row.original.id}`
                        : '#'
                    }
                    className={`btn btn-sm btn-soft-info ${
                      cellProps.row.original?.status === 'draft' || cellProps.row.original?.status === 'rejected'
                        ? ''
                        : 'opacity-50'
                    }`}
                    id={`edittooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-pencil-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`edittooltip-${cellProps.row.original.id}`}
                    >
                      {cellProps.row.original?.status === 'draft' || cellProps.row.original?.status === 'rejected'
                        ? 'Sửa'
                        : 'Đã phê duyệt không thể sửa'}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>

              <li>
                <Link
                  to='#'
                  className={`btn btn-sm btn-soft-danger ${cellProps.row.original?.status !== 'draft' && 'opacity-50'}`}
                  onClick={() => {
                    if (cellProps.row.original?.status === 'draft') {
                      const programData = cellProps.row.original
                      onClickDelete(programData)
                    }
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className='mdi mdi-delete-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    {cellProps.row.original?.status !== 'draft' ? 'Đã phê duyệt không thể xóa' : 'Xóa'}
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        }
      }
    ],
    []
  )
  const setGlobalFilterUser = async (data) => {
    if (data?.start_date) {
      data.start_date = moment(data.start_date)?.startOf('day')?.toDate()
    }
    if (data?.end_date) {
      data.end_date = moment(data.end_date)?.endOf('day')?.toDate()
    }

    setStorage('filterManageProgram', data)

    dispatch(onGetManageProgram(data))
    dispatch(setFilter({ ...data, page: 1 }))
  }

  const onDelete = async () => {
    try {
      const res = await patchStatusProgramAPI({ id: selected.id, status: 'terminated' })
      if (res) {
        toast(res.message, { type: 'success' })
        dispatch(onGetManageProgram(filter))
        setShowDelete(false)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const onChangeStatus = async () => {
    try {
      const res = await patchStatusProgramAPI({
        id: selected.id,
        status: selected.status === 'active' ? 'pause' : 'accepted'
      })
      if (res) {
        toast(
          selected.status === 'active'
            ? 'Chương trình khuyến mãi được tạm dừng thành công'
            : 'Chương trình khuyến mãi được tiếp tục áp dụng',
          { type: 'success' }
        )
        dispatch(onGetManageProgram(filter))
        // onChangeStatus(false)
        setShowPause(false)
        setShowPlay(false)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    dispatch(onGetManageProgram({ ...filter, page }))
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{ManageProgramVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{ManageProgramVN.programList}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={PROGRAMING_MANAGEMENT_CODE.ADMIN_CREATE_PROGRAMMING}
                        >
                          <button
                            onClick={() => {
                              navigate('/manage-program/create')
                              // setShowForm(true)
                              // dispatch(setTypePopupProductActivation("create"))
                              dispatch(getProgramDetailSuccess({}))
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {ManageProgramVN.create}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={programs}
                      isGlobalFilter
                      setGlobalFilterUser={setGlobalFilterUser}
                      isManageProgramGlobalFilter
                      placeholderSearch={ManageProgramVN.filter.search.placeholder}
                      isPagination
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      useAPIChangePaging
                      gotoPageCustom={goToPage}
                      total={total}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDelete}
        onConfirmClick={onDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        title='Xóa chương trình'
        onCloseClick={() => setShowDelete(false)}
        description={`Bạn có chắc bạn muốn xóa chương trình`}
      />
      <ConfirmModal
        show={showPause}
        onConfirmClick={onChangeStatus}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        onCloseClick={() => setShowPause(false)}
        title='Tạm dừng chương trình'
        description={`Chương trình tạm dừng sẽ không áp dụng thưởng.\n Bạn có chắc chắn muốn tạm dừng?`}
      />
      <ConfirmModal
        show={showPlay}
        onConfirmClick={onChangeStatus}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        onCloseClick={() => setShowPlay(false)}
        title='Tiếp tục chương trình'
        description={`Bạn có chắc chắn muốn tiếp tục chương trình?`}
      />
    </React.Fragment>
  )
}

export default ManageProgram
