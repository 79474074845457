import { StatusAccumulationOptions } from 'constants/constants'
import { AccumulationVN, ManageShopVN } from 'constants/vn'
import { formateDate, formateDateTime } from 'helpers/global_helper'
import React, { useState } from 'react'
import { Badge, Input, UncontrolledTooltip } from 'reactstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

const Serial = (cell) => {
  return (
    <>
      {cell.row.original?.userRewardIsDeleted && (
        <>
          <i
            className='mdi mdi-recycle-variant'
            style={{ marginRight: '4px', color: 'red' }}
            id={`viewtooltip-bravo-${cell.row.original.id}`}
          />
          <UncontrolledTooltip trigger='hover' placement='top' target={`viewtooltip-bravo-${cell.row.original.id}`}>
            Sản phẩm bị thu hồi
          </UncontrolledTooltip>
        </>
      )}
      <span>{cell.value ? cell.value : '--'}</span>
    </>
  )
}

const AccumulationIndustrySector = (cell) => {
  return cell.value ? cell.value : '--'
}

const AccumulationCategory = (cell) => {
  return cell.value ? cell.value : '--'
}

const AccumulationModel = (cell) => {
  return cell.value ? cell.value : '--'
}

const Shop = (cell) => {
  return (
    <div>
      <p className='m-0'>{cell.value ? cell.value : cell.row.original?.usersName}</p>
      <span>{cell.row.original?.usersPhone}</span>
      <p>
        <span>
          {cell.row.original?.zoneName} - {cell.row.original?.subZoneName} - {cell.row.original?.provinceName} -{' '}
          {cell.row.original?.districtName} - {cell.row.original?.wardName}
        </span>
      </p>
    </div>
  )
}

const Ratio = (cell) => {
  return (
    <div>
      <p className='m-0'>
        <Badge className='badge-soft-success'>
          Hoàn thành: {cell.value ? cell.value : cell.row.original?.totalCompleted}
        </Badge>
      </p>
      <p className='m-0'>
        <Badge className='badge-soft-danger'>
          Thất bại: {cell.value ? cell.value : cell.row.original?.totalFailed}
        </Badge>
      </p>
      <p className='m-0'>
        <Badge className='badge-soft-info'>
          Đang xử lý: {cell.value ? cell.value : cell.row.original?.totalProcessing}
        </Badge>
      </p>
      <p className='m-0'>
        <Badge className='badge-soft-warning'>
          Pending: {cell.value ? cell.value : cell.row.original?.totalPending}
        </Badge>
      </p>
    </div>
  )
}

const CustomerCareProductListStatus = (cell) => {
  const isAccumulation = cell.row.original?.statuses?.includes('accumulation')
  const isActivation = cell.row.original?.statuses?.includes('activation')

  return (
    <div>
      <p className='m-0'>
        <Badge className={isAccumulation ? 'badge-soft-success' : 'badge-soft-warning'}>
          {isAccumulation ? 'Đã tích luỹ' : 'Chưa tích luỹ'}
        </Badge>
      </p>
      <p className='m-0'>
        <Badge className={isActivation ? 'badge-soft-success' : 'badge-soft-warning'}>
          {isActivation ? 'Đã kích hoạt' : 'Chưa kích hoạt'}
        </Badge>
      </p>
    </div>
  )
}

const Status = (cell) => {
  switch (cell.value) {
    case 'pending':
      return <Badge className='badge-soft-info'>{'Đang xử lý'}</Badge>
    case 'failed':
      return <Badge className='badge-soft-warning'>{'Thất bại'}</Badge>
    case 'completed':
      return <Badge className='badge-soft-success'>{'Hoàn thành'}</Badge>
  }
}

const Zone = (cell) => {
  return cell.value ? cell.value : '--'
}
const ActivatedDate = (cell) => {
  return cell.value ? formateDateTime(cell.value) : '--'
}
const StatusAccumulation = (cell) => {
  switch (cell.value) {
    case 'no_reward':
      return <Badge className='badge-soft-secondary'>{StatusAccumulationOptions[0].label}</Badge>
    case 'waiting_for_control':
      return <Badge className='badge-soft-info'>{StatusAccumulationOptions[1].label}</Badge>
    case 'for_control':
      return <Badge className='badge-soft-success'>{StatusAccumulationOptions[2].label}</Badge>
    case 'payed':
      return <Badge className='badge-soft-warning'>{StatusAccumulationOptions[3].label}</Badge>
  }
}

const StatusImportFile = (cell) => {
  switch (cell.value) {
    case 'pendding':
      return <Badge className='badge-soft-warning'>{'Đang chờ xử lý'}</Badge>
    case 'completed':
      return <Badge className='badge-soft-success'>{'Hoàn thành'}</Badge>
    case 'failed':
      return <Badge className='badge-soft-danger'>{'Thất bại'}</Badge>
  }
}

// Hàm chuyển đổi từ số thập phân sang độ, phút, giây (DMS)
const convertToDMS = (decimal, isLat) => {
  const absolute = Math.abs(decimal)
  const degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  const minutes = Math.floor(minutesNotTruncated)
  const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(1)

  const direction = decimal >= 0 ? (isLat ? 'N' : 'E') : isLat ? 'S' : 'W'

  return `${degrees}°${minutes}'${seconds}"${direction} `
}

// Hàm để chuyển latitude và longitude thành chuỗi tọa độ
const LongLatToDMS = (cell) => {
  const lat = parseFloat(cell?.value?.userRewardLatitude ? cell?.value?.userRewardLatitude : 0).toFixed(6) // Làm tròn thành 6 chữ số sau dấu phẩy
  const long = parseFloat(cell?.value?.userRewardLongitude ? cell?.value?.userRewardLongitude : 0).toFixed(6) // Làm tròn thành 6 chữ số sau dấu phẩy

  // Nếu tọa độ là 0, không hiển thị gì
  if (lat === '0.000000' && long === '0.000000') {
    return null
  } else {
    const dmsLatitude = convertToDMS(cell?.value?.userRewardLatitude, true) // true => là latitude
    const dmsLongitude = convertToDMS(cell?.value?.userRewardLongitude, false) // false => là longitude

    return (
      <>
        <a href={`https://www.google.com/maps?q=${lat},${long}`} target='_blank' rel='noopener noreferrer'>
          {dmsLatitude} {dmsLongitude}
        </a>
      </>
    )
  }
}

const Locate = (cell) => {
  return (
    <>
      <a href={cell} target='_blank' rel='noopener noreferrer'>
        (`(${lat}, ${long})`)
      </a>
    </>
  )
}

export {
  AccumulationModel,
  AccumulationCategory,
  Shop,
  Zone,
  AccumulationIndustrySector,
  StatusAccumulation,
  Serial,
  ActivatedDate,
  Ratio,
  StatusImportFile,
  Status,
  LongLatToDMS,
  Locate,
  CustomerCareProductListStatus
}
